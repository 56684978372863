import Vue from "vue";
import {format, parseISO} from "date-fns";
import {currencyFilter} from "./CurrencyFilter.ts";

export const DATE_FORMAT = "dd/MM/yyyy";
export const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm";

export const DATE_TIME_LONG_FORMAT = "yyyy-MM-dd'T'HH:mm";

function formatOrEmpty(value, formatter, pattern) {
  return value ? format(formatter(), pattern) : "";
}

export const formatDate = value =>
  formatOrEmpty(value, () => parseISO(value), DATE_FORMAT);


export const formatDateTime = value =>
  formatOrEmpty(value, () => parseISO(value), DATE_TIME_FORMAT);

export const formatMillis = value =>
  formatOrEmpty(value, () => new Date(value), DATE_TIME_FORMAT);

export function initFilters() {
  Vue.filter("formatDate", formatDate);
  Vue.filter("formatDateTime", formatDateTime);
  Vue.filter("formatMillis", formatMillis);
  Vue.filter("toCurrency", currencyFilter);
}
